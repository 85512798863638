import "bootstrap/dist/css/bootstrap.css"
import { BrowserRouter as Router } from "react-router-dom"
import Routes from "./Routes"
import "./App.css"
import ScrollToTop from "./ScrollToTop"

function App() {
   return (
      <div className="App">
         <Router>
            <ScrollToTop />
            <Routes />
         </Router>
      </div>
   )
}

export default App
