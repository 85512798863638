import React, { useRef } from "react"
import AuthForm from "./../components/authForm"
import { handleLogin } from "../../utilities/landingPageAPIs"
import { AUTHENTICATION_COMPONENTS } from "../../utilities/enums"

function LoginForm() {
   const emailRef = useRef()
   const passwordRef = useRef()

   const signIn = async (event) => {
      event.preventDefault()

      const body = JSON.stringify({
         email: emailRef.current.value,
         password: passwordRef.current.value,
      })

      handleLogin({ body: body })
   }

   return (
      <AuthForm
         emailRef={emailRef}
         passwordRef={passwordRef}
         currentPage={AUTHENTICATION_COMPONENTS.SIGN_IN}
         submitFunction={signIn}
      />
   )
}

export default LoginForm
