import axios from "axios"
import { logout } from "./clientPageApis"

const withCredentials = true
const headers = {
   "Content-Type": "application/json",
}

async function executeAxios({
   config,
   onCancel = {},
   functionName = "an api function",
   navigate,
}) {
   try {
      const response = await axios(config)

      if (response.status === 204) return null
      if (response.error) return Promise.reject({ message: response.error })
      return response
   } catch (error) {
      if (error.name === "CanceledError") {
         console.info(`Extra Api call has been canceled for ${functionName}`)
         return Promise.resolve(onCancel)
      }

      if (error.response.status === 401) logout({ navigate })

      console.error(error.stack)
      throw error
   }
}

// admin notifications

export async function getAdminNotificationsData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/getNotifications",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getAdminNotificationsData",
   })
}

// admin goals page

export async function getAllAdminGoalsData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/goals",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getAllAdminGoalsData",
   })
}

export async function createOrganizationGoal({
   formD,
   nameValue,
   descriptionValue,
   rewardMinValue,
   rewardMaxValue,
   rewardStaticValue,
   investmentMinValue,
   investmentMaxValue,
   verificationValue,
   verificationCountValue,
   daysToCompleteValue,
   categoryValue,
   openDateValue,
   closeDateValue,
   difficultyLevel,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/createOrgGoal",
      withCredentials,
      headers,
      data: formD,
      params: {
         name: nameValue,
         description: descriptionValue,
         rewardMin: rewardMinValue,
         rewardMax: rewardMaxValue,
         rewardStatic: rewardStaticValue,
         investmentMin: investmentMinValue,
         investmentMax: investmentMaxValue,
         verification: verificationValue,
         verificationCount: verificationCountValue,
         daysToComplete: daysToCompleteValue,
         category: categoryValue,
         openDate: openDateValue,
         closeDate: closeDateValue,
         difficulty: difficultyLevel,
         orgID: sessionStorage.getItem("orgID"),
         adminEmail: sessionStorage.getItem("email"),
      },
   }

   return await executeAxios({ config, navigate })
}

export async function approveClientGoal({
   goalID,
   name,
   clientEmail,
   isApproved,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/approveClientGoal",
      withCredentials,
      headers,
      params: {
         goalID,
         name,
         clientEmail,
         isApproved,
         orgID: sessionStorage.getItem("orgID"),
      },
   }

   return await executeAxios({ config, navigate })
}

export async function editOrganizationGoal({
   orgGoalIDIndex,
   nameValue,
   descriptionValue,
   rewardMinValue,
   rewardMaxValue,
   rewardStaticValue,
   investmentMinValue,
   investmentMaxValue,
   verificationValue,
   verificationCountValue,
   daysToCompleteValue,
   categoryValue,
   openDateValue,
   closeDateValue,
   difficultyValue,
   formD,
   oldPhoto,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/editOrgGoal",
      withCredentials,
      headers,
      data: formD,
      params: {
         orgGoalIDIndex,
         name: nameValue,
         description: descriptionValue,
         rewardMin: rewardMinValue,
         rewardMax: rewardMaxValue,
         rewardStatic: rewardStaticValue,
         investmentMin: investmentMinValue,
         investmentMax: investmentMaxValue,
         verification: verificationValue,
         verificationCount: verificationCountValue,
         daysToComplete: daysToCompleteValue,
         category: categoryValue,
         openDate: openDateValue,
         closeDate: closeDateValue,
         difficulty: difficultyValue,
         adminEmail: sessionStorage.getItem("email"),
         ...(oldPhoto && { oldPhoto }),
      },
   }

   return await executeAxios({ config, navigate })
}

export async function deleteOrganizationGoal({
   orgGoalIDIndex,
   navigate,
   goalName,
}) {
   const config = {
      method: "delete",
      url: "/api/clientAdmin/deleteOrganizationGoal",
      withCredentials,
      headers,
      params: {
         orgGoalIDIndex,
         goalName,
         orgID: sessionStorage.getItem("orgID"),
      },
   }

   return await executeAxios({ config, navigate })
}

// admin dashboard page

export async function getAdminDashboardData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/dashboard",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getAdminDashboardData",
   })
}

export function getMonthAndDate(dateData) {
   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ]
   const newDate = new Date(dateData)

   const month = months[newDate.getMonth()]
   const date = newDate.getDate()

   if (date === 1 || date === 21) return `${month} ${date}st`
   if (date === 2 || date === 22) return `${month} ${date}nd`
   if (date === 3 || date === 33) return `${month} ${date}rd`

   return `${month} ${date}th`
}

// admin feed page

export async function getAdminFeedData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/clientFeedView",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getAdminFeedData",
   })
}

// admin users page

export async function approveClientAdmin({ body, navigate }) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/acceptClientAdmin",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      data: body,
   }

   return await executeAxios({ config, navigate })
}

export async function approveUser({ body, navigate }) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/acceptPendingUsers",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      data: body,
   }

   return await executeAxios({ config, navigate })
}

export async function rejectUser({ body, navigate }) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/rejectPendingUser",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      data: body,
   }

   return await executeAxios({ config, navigate })
}

export async function removeUser({ body, navigate }) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/removeCurrentUser",
      withCredentials,
      headers,
      params: {},
      data: body,
   }

   return await executeAxios({ config, navigate })
}

export async function getCurrentUsers({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/users",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getCurrentUsers",
   })
}

export async function getPendingUsers({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/pendingUsers",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getPendingUsers",
   })
}

// admin rewards page

export async function getAdminRewardsData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/getRewards",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getAdminRewardsData",
   })
}

export async function createReward({
   orgIndex,
   formD,
   name,
   description,
   price,
   quantity,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/createOrgReward",
      withCredentials,
      headers,
      data: formD,
      params: {
         orgIndex,
         name,
         description,
         price,
         quantity,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function editReward({
   orgIndex,
   formD,
   name,
   description,
   price,
   quantity,
   orgRewardIndex,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/editOrgReward",
      withCredentials,
      headers,
      data: formD,
      params: {
         orgIndex,
         name,
         description,
         price,
         quantity,
         orgRewardIndex,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function deleteReward({ orgIndex, rewardIndex, navigate }) {
   const config = {
      method: "delete",
      url: "/api/clientAdmin/deleteOrganizationReward",
      withCredentials,
      headers,
      params: {
         orgIndex,
         rewardIndex,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function getRewardPurchaseAndRedeemInformation({
   abortController,
   navigate,
}) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/getRewardPurchaseAndRedeemInformation",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getRewardPurchaseAndRedeemInformation",
   })
}

// report page

export async function getReportedPhotosWithGoalsData({
   navigate,
   abortController,
}) {
   const config = {
      method: "get",
      url: "/api/clientAdmin/getReportedPhotos",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getReportedPhotosWithGoalsData",
   })
}

export async function rejectClientPhoto({
   navigate,
   goalID,
   photoID,
   reportedUserEmail,
   goalName,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/rejectPhoto",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         goalID,
         photoID,
         reportedUserEmail,
         goalName,
      },
   }

   return await executeAxios({
      config,
      navigate,
   })
}

export async function acceptClientPhoto({
   navigate,
   userGoalID,
   reportedPhotoID,
   reportedUserEmail,
   goalName,
}) {
   const config = {
      method: "post",
      url: "/api/clientAdmin/acceptPhoto",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         userGoalID,
         reportedPhotoID,
         reportedUserEmail,
         goalName,
      },
   }

   return await executeAxios({
      config,
      navigate,
   })
}

export async function deleteGoal({ navigate, goalID }) {
   const config = {
      method: "delete",
      url: "/api/clientAdmin/deleteOrganizationGoal",
      withCredentials,
      headers,
      params: {
         goalID: goalID,
      },
   }
   return await executeAxios({
      config,
      navigate,
   })
}
