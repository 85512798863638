import React, { useState, useEffect, Suspense, useRef, useMemo } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import {
   logout,
   getUserPoints,
   getNotifications,
   getFailedUserGoalsDonations,
} from "../../../utilities/clientPageApis"
import useComponentVisible from "../../../utilities/useComponentVisible"
import SidebarClient from "./SidebarClient"

import AnimatedNumber from "react-animated-number"

import "./topBarClient.css"
import openProfile from "../../../assets/top-openprofile.png"
import Notifications from "./Notifications.js"
import pointsTopBarImage from "../../../assets/points-topBar-image.svg"
import logo from "../../../assets/logo.svg"

export default function TopBarClient() {
   const [animateNumberIncrease, setAnimateNumberIncrease] = useState(false)
   const [startNumberAnimation, setStartNumberAnimation] = useState(false)
   const [animatePause, setAnimatePause] = useState(false)
   const [rewardPoints, setRewardPoints] = useState(0)
   const [userIndex, setUserIndex] = useState(0)
   const [newNotifications, setNewNotifications] = useState([])
   const [oldNotifications, setOldNotifications] = useState([])
   const [refreshTopBar, setRefreshTopBar] = useState(false)
   const [goalsNeedDonation, setGoalsNeedDonation] = useState(false)
   const { isComponentVisible, setIsComponentVisible } =
      useComponentVisible(false)
   const navigate = useNavigate()
   const prevRewardPoints = usePreviousRewardPoints(rewardPoints)

   useEffect(() => {
      const abortController = new AbortController()

      async function loadUserPointsData() {
         try {
            const [
               userPointsResponse,
               notificationsResponse,
               FailedUserGoalsResponse,
            ] = await Promise.all([
               getUserPoints({ navigate, abortController }),
               getNotifications({ navigate, abortController }),
               getFailedUserGoalsDonations({
                  abortController: abortController,
                  navigate: navigate,
               }),
            ])

            if (
               !userPointsResponse.data ||
               !notificationsResponse.data ||
               !FailedUserGoalsResponse.data
            )
               return null

            const { rewardPoints: currentRewardPoints, userIndex } =
               userPointsResponse.data
            const { notifications } = notificationsResponse.data
            const { failedUserGoalsDonations } = FailedUserGoalsResponse.data

            if (failedUserGoalsDonations.length > 0) setGoalsNeedDonation(true)
            setRewardPoints(currentRewardPoints || 0)
            if (
               !isNaN(Number(prevRewardPoints)?.toFixed(2)) &&
               Number(prevRewardPoints) !== 0 &&
               Number(prevRewardPoints) !== Number(currentRewardPoints)
            ) {
               setAnimateNumberIncrease(true)

               setTimeout(() => {
                  setStartNumberAnimation(true)
               }, 1200)

               setTimeout(() => {
                  setAnimatePause(true)
               }, 4000)

               setTimeout(() => {
                  setAnimateNumberIncrease(false)
                  setAnimatePause(false)
                  setStartNumberAnimation(false)
               }, 5000)
            }

            const oldOnes = []
            const newOnes = []

            for (const notification of notifications) {
               if (
                  notification.seenBy.includes(sessionStorage.getItem("email"))
               )
                  oldOnes.push(notification)
               else newOnes.push(notification)
            }
            setUserIndex(userIndex)
            setNewNotifications(newOnes)
            setOldNotifications(oldOnes)
         } catch (error) {
            console.info(error.message)
         }
      }

      loadUserPointsData()
      return () => abortController.abort()
   }, [animateNumberIncrease, navigate, prevRewardPoints, refreshTopBar])

   useEffect(() => {}, [prevRewardPoints, rewardPoints])

   return (
      <div
         className={`client__page__wrapper ${
            isComponentVisible && "client__page__wrapper--sidebar__open"
         }`}
         style={{ boxSizing: "unset" }}
      >
         <SidebarClient
            isComponentVisible={isComponentVisible}
            setIsComponentVisible={setIsComponentVisible}
         />
         <div
            className={`client__black__screen ${
               (isComponentVisible || animateNumberIncrease) &&
               "client__black__screen--sidebar__open"
            }`}
            onClick={() => setIsComponentVisible(false)}
         ></div>
         <div
            className={`client__page__content__wrapper ${
               isComponentVisible &&
               "client__page__content__wrapper--sidebar__open"
            }`}
         >
            <div className="client__topBar__wrapper">
               <div className="client__logo__image__wrapper">
                  <img className="client__logo__image" src={logo} alt="logo" />
               </div>
               <div className="client__topBar__content">
                  <div className="client__topBar__content__section">
                     {goalsNeedDonation && (
                        <div
                           className="client__topBar__donate__wrapper"
                           onClick={() => navigate("donations")}
                        >
                           <div className="client__topBar__donate__text">
                              Goal Failed:
                           </div>
                           <div className="client__topBar__donate__button">
                              Click here to donate
                           </div>
                        </div>
                     )}
                     <div
                        className="client__topBar__points__info__wrapper__size__controller"
                        onClick={() => navigate("/client/transactions")}
                     >
                        <div
                           className={`client__topBar__points__info__wrapper ${
                              animateNumberIncrease &&
                              "client__topBar__points__info__wrapper--opacity__none"
                           }`}
                        >
                           <div className="client__topBar__points__info__text__wrapper">
                              <div className="client__topBar__points__reward">
                                 Available:{" "}
                                 {rewardPoints >= 0 ? (
                                    <strong>{rewardPoints?.toFixed(2)}</strong>
                                 ) : (
                                    <strong style={{ color: "darkRed" }}>
                                       {rewardPoints?.toFixed(2)}
                                    </strong>
                                 )}{" "}
                                 RPts
                              </div>
                           </div>
                           <img
                              className="client__topBar__points__image"
                              src={pointsTopBarImage}
                              alt="points info pic"
                           />
                        </div>

                        <div
                           className={`client__topBar__points__info__wrapper client__topBar__points__info__wrapper--dynamic 
                           ${
                              animateNumberIncrease &&
                              !animatePause &&
                              "client__topBar__points__info__wrapper--dynamic__enabled"
                           }
                           ${
                              animatePause &&
                              animateNumberIncrease &&
                              "client__topBar__points__info__wrapper--dynamic__initial"
                           } 
                           `}
                        >
                           <div className="client__topBar__points__info__text__wrapper">
                              <div className="client__topBar__points__reward">
                                 Available:{" "}
                                 {rewardPoints >= 0 ? (
                                    <strong>
                                       {
                                          <AnimatedNumber
                                             initialValue={prevRewardPoints}
                                             value={
                                                startNumberAnimation
                                                   ? prevRewardPoints
                                                   : prevRewardPoints
                                             }
                                             style={{
                                                transition: "0.8s ease-out",
                                                textTransform: "bold",
                                                transitionProperty:
                                                   "background-color, color, opacity",
                                             }}
                                             frameStyle={(perc) =>
                                                perc === 100
                                                   ? {}
                                                   : { opacity: "0.5" }
                                             }
                                             duration={2000}
                                             formatValue={(n) => n?.toFixed(2)}
                                          />
                                       }
                                    </strong>
                                 ) : (
                                    <strong style={{ color: "darkRed" }}>
                                       {rewardPoints?.toFixed(2)}
                                    </strong>
                                 )}{" "}
                                 RPts
                              </div>
                           </div>
                           <img
                              className="client__topBar__points__image"
                              src={pointsTopBarImage}
                              alt="points info pic"
                           />
                        </div>
                     </div>
                  </div>
                  <div className="client__topBar__content__section">
                     <Notifications
                        newNotifications={newNotifications}
                        oldNotifications={oldNotifications}
                        setOldNotifications={setOldNotifications}
                        setNewNotifications={setNewNotifications}
                     />
                     <div className="client__topBar__dropdown">
                        <button
                           type="button"
                           className="client__topBar__profile__wrapper link__dropdown"
                        >
                           <div
                              className="client__topBar__profile__image"
                              style={{
                                 backgroundImage: `url(${sessionStorage.getItem(
                                    "pfp"
                                 )})`,
                              }}
                           ></div>
                           <div className="client__topBar__profile__name">
                              {sessionStorage.getItem("name")?.split(" ")[0]}
                           </div>
                           <img
                              className="client__topBar__profile__image__Arrow"
                              src={openProfile}
                              alt="profile arrow"
                           />
                        </button>
                        <div className="client__topBar__dropdown__menu">
                           <ul className="client__topBar__dropdown__menu__item__wrapper">
                              <li
                                 className="client__topBar__dropdown__menu__item"
                                 onClick={() =>
                                    navigate(`/client/user/feed/${userIndex}`)
                                 }
                              >
                                 Profile/Settings
                              </li>
                              <li
                                 className="client__topBar__dropdown__menu__item"
                                 onClick={() =>
                                    navigate("/client/transactions")
                                 }
                              >
                                 Transaction
                              </li>
                              <li
                                 className="client__topBar__dropdown__menu__item"
                                 onClick={() => logout({ navigate: navigate })}
                              >
                                 Logout
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <span className="client__head__span"></span>
            <Suspense fallback={<div>Loading..</div>}>
               <Outlet context={[setRefreshTopBar]} />
            </Suspense>
         </div>
      </div>
   )
}

function usePreviousRewardPoints(value) {
   const ref = useRef()

   useEffect(() => {
      ref.current = value
   }, [value])

   return ref.current
}
