import React, { useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
   AUTHENTICATION_COMPONENTS,
   PAYPAL_BUTTON_TYPE,
   SIGN_UP_ORG_ID,
} from "../../utilities/enums"

import logo from "../../assets/logo.svg"
import "./authForm.css"
import PaypalPayments from "./../../views/client/pagesClient/verifyClient/childComponents/buttons/donate/PaypalDonations"

// import googleLogo from "../../assets/InitialPageImages/signUpLogIn/googleLogo.png"
// import facebookLogo from "../../assets/InitialPageImages/signUpLogIn/facebookLogo.png"
import backgroundImage from "../../assets/InitialPageImages/signUpLogIn/auth-image.jpg"

export default function AuthForm({
   firstNameRef,
   lastNameRef,
   employeeCountRef,
   termsOfServiceRef,
   numberRef,
   emailRef,
   passwordRef,
   confirmPasswordRef,
   organizationRef,
   currentPage = AUTHENTICATION_COMPONENTS.SIGN_IN,
   submitFunction,
}) {
   const navigate = useNavigate()
   const queryParams = new URLSearchParams(window.location.search)
   const [accountType, setAccountType] = useState(
      queryParams.get("accountType")
   )
   const [paymentApproved, setPaymentApproved] = useState(false)

   if (organizationRef?.current && organizationRef.current.value) {
      organizationRef.current.value = accountType
   }

   const showBackground =
      currentPage !== AUTHENTICATION_COMPONENTS.DEMO
         ? `url(${backgroundImage})`
         : "none"
   const height =
      currentPage !== AUTHENTICATION_COMPONENTS.DEMO ? "100vh" : "100%"
   return (
      <div
         className="auth-form-page"
         style={{
            backgroundImage: showBackground,
            height: height,
         }}
      >
         <form
            className={`auth-form ${
               currentPage === AUTHENTICATION_COMPONENTS.DEMO
                  ? AUTHENTICATION_COMPONENTS.DEMO
                  : currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP
                  ? "sign-up"
                  : "sign-in"
            }`}
         >
            {currentPage !== AUTHENTICATION_COMPONENTS.DEMO && (
               <>
                  <div className="auth__form__backArrow__wrapper">
                     <div
                        className="auth__form__backArrow__button__wrapper"
                        onClick={() => navigate("/")}
                     >
                        <svg
                           width="10"
                           height="16"
                           viewBox="0 0 10 16"
                           className="auth__form__backArrow"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9.1705 0.454505C9.60983 0.893845 9.60983 1.60616 9.1705 2.0455L3.21599 8L9.1705 13.9545C9.60983 14.3938 9.60983 15.1062 9.1705 15.5455C8.73116 15.9848 8.01884 15.9848 7.5795 15.5455L0.829505 8.7955C0.390165 8.35616 0.390165 7.64384 0.829505 7.2045L7.5795 0.454505C8.01884 0.015165 8.73116 0.015165 9.1705 0.454505Z"
                              fill="currentColor"
                           />
                        </svg>
                        <div className="auth__form__backArrow__text">
                           Go Back
                        </div>
                     </div>

                     <div className="auth__form__dark__color">
                        Create Account:{" "}
                        <span
                           className={`auth__form__light__color ${
                              accountType === SIGN_UP_ORG_ID.INDIVIDUAL &&
                              "auth__form__text__clicked"
                           }`}
                           onClick={() => {
                              setAccountType(SIGN_UP_ORG_ID.INDIVIDUAL)
                              navigate(
                                 `/signUp?accountType=${SIGN_UP_ORG_ID.INDIVIDUAL}`
                              )
                           }}
                        >
                           Individual
                        </span>{" "}
                        |{" "}
                        <span
                           className={`auth__form__light__color ${
                              accountType === SIGN_UP_ORG_ID.SALES &&
                              "auth__form__text__clicked"
                           }`}
                           onClick={() => {
                              setAccountType(SIGN_UP_ORG_ID.SALES)
                              navigate(
                                 `/signUp?accountType=${SIGN_UP_ORG_ID.SALES}`
                              )
                           }}
                        >
                           Sales
                        </span>
                     </div>
                  </div>
                  <img className="auth-form--logo" src={logo} alt="logo" />
               </>
            )}
            <h1
               className={`auth-form--header ${
                  currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP
                     ? "sign-up"
                     : "sign-in"
               }`}
            >
               {currentPage === AUTHENTICATION_COMPONENTS.DEMO
                  ? "Request Demo"
                  : currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP
                  ? "Create Account"
                  : currentPage === AUTHENTICATION_COMPONENTS.FORGOT_PASSWORD
                  ? "Password Recovery"
                  : "Welcome"}
            </h1>
            {currentPage === AUTHENTICATION_COMPONENTS.SIGN_IN && (
               <>
                  <p className="auth-form--text">Employee Engagement Systems</p>
                  <hr className="auth-form--horizontal-line" />
               </>
            )}
            <div className="auth-form-input--wrapper">
               {(currentPage === AUTHENTICATION_COMPONENTS.DEMO ||
                  currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP) && (
                  <>
                     <label htmlFor="firstName">First Name</label>
                     <input
                        id="firstName"
                        type="text"
                        ref={firstNameRef}
                        required
                     />

                     <label htmlFor="lastName">Last Name</label>
                     <input
                        id="lastName"
                        type="text"
                        ref={lastNameRef}
                        required
                     />

                     <label htmlFor="contactNo">Phone Number</label>
                     <input
                        id="contactNo"
                        type="tel"
                        ref={numberRef}
                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        placeholder="1234546784"
                        required
                     />
                  </>
               )}

               <label htmlFor="email">Email</label>
               <input id="email" type="email" ref={emailRef} required />

               {currentPage === AUTHENTICATION_COMPONENTS.DEMO && (
                  <>
                     <label htmlFor="noOfEmployees">No. Of Employees</label>
                     <input
                        id="noOfEmployees"
                        type="text"
                        ref={employeeCountRef}
                        required
                     />
                  </>
               )}

               {currentPage !== AUTHENTICATION_COMPONENTS.DEMO && (
                  <>
                     <label
                        htmlFor="password"
                        className="auth-form-label--password"
                     >
                        Password
                     </label>
                     <input
                        id="password-input"
                        type="password"
                        ref={passwordRef}
                        required
                     />
                  </>
               )}

               {currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP && (
                  <>
                     <label
                        htmlFor="confirmPassword"
                        className="auth-form-label--password"
                     >
                        Confirm Password
                     </label>
                     <input
                        id="confirmPassword"
                        type="password"
                        ref={confirmPasswordRef}
                        required
                     />

                     <label htmlFor="organization">Organization ID</label>
                     <input
                        id="organization"
                        type="text"
                        ref={organizationRef}
                        defaultValue={accountType !== null ? accountType : null}
                        disabled={accountType !== null}
                        required
                     />
                  </>
               )}
            </div>

            {currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP && (
               <div className="client__setting__form__notifications__wrapper">
                  <label className="client__setting__form__checkbox__label">
                     <a
                        className="client__setting__form__checkbox__link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.google.com/document/d/1jCO09n4pTZINuOj80IzEV4dnvxU57K1F/edit?usp=sharing&ouid=108869260069065023601&rtpof=true&sd=true"
                     >
                        Terms of Service
                     </a>
                     <input
                        ref={termsOfServiceRef}
                        className="client__setting__form__checkbox__input"
                        type="checkbox"
                     />
                     <span className="client__setting__form__checkbox__check"></span>
                  </label>
               </div>
            )}

            {currentPage === AUTHENTICATION_COMPONENTS.SIGN_IN && (
               <Link
                  className="auth-form-link--forgot-password"
                  to="/forgot-Password"
               >
                  Forgot Password?
               </Link>
            )}

            <button
               className={`auth-form--button ${
                  currentPage === AUTHENTICATION_COMPONENTS.DEMO &&
                  "auth-form--button__darker"
               }`}
               onClick={(e) => submitFunction(e, accountType)}
            >
               {/* <div>
                  <PaypalPayments
                     buttonType={PAYPAL_BUTTON_TYPE.CHECKOUT}
                     amountToPay={10}
                     setPaymentApproved={setPaymentApproved}
                  />
               </div> */}
               {currentPage === AUTHENTICATION_COMPONENTS.DEMO
                  ? "Get Started"
                  : currentPage === AUTHENTICATION_COMPONENTS.SIGN_UP
                  ? "Sign Up"
                  : "Sign In"}
            </button>

            {currentPage !== AUTHENTICATION_COMPONENTS.DEMO ? (
               currentPage === AUTHENTICATION_COMPONENTS.SIGN_IN ? (
                  <p className="auth-form-prompt--sign-up">
                     Don't have an account yet?{" "}
                     <Link id="signUp-link" to="/signUp">
                        Sign up
                     </Link>
                  </p>
               ) : (
                  <div className="auth-form-prompt--sign-in">
                     <p>
                        Already have an account?{" "}
                        <Link id="signIn-link" to="/login">
                           Sign In
                        </Link>
                     </p>
                  </div>
               )
            ) : null}
         </form>
      </div>
   )
}
