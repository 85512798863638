import axios from "axios"

const withCredentials = true
const headers = {
   "Content-Type": "application/json",
}

async function executeAxios({
   config,
   onCancel = {},
   functionName = "an api function",
   navigate,
}) {
   try {
      const response = await axios(config)
      if (response.status === 204) return null

      if (response.error) return Promise.reject({ message: response.error })

      return response
   } catch (error) {
      if (error.name === "CanceledError") {
         console.info(`Extra Api call has been canceled for ${functionName}`)
         return Promise.resolve(onCancel)
      }

      if (error.response.status === 401) {
         logout({ navigate })
      }

      if (error.response.status === 418) return null

      console.error(error)
      throw error
   }
}

export async function logout({ navigate }) {
   const config = {
      method: "get",
      url: "/api/landing/logout",
      withCredentials,
      headers,
   }
   await executeAxios({ config, navigate })
   sessionStorage.clear()
   navigate("/login", { replace: true })
}

// dashboard functions

export async function getDashboardData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/dashboard",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      functionName: "getDashboardData",
      navigate,
   })
}

export async function getCurrentUserGoals({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getCurrentUserGoals",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      functionName: "getCurrentUserGoals",
      navigate,
   })
}

export async function getLeaderboardData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/leaderboard",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         // email: sessionStorage.getItem("email"),
      },
      // signal: abortController.signal,
   }

   return await executeAxios({
      config,
      functionName: "getLeaderboardData",
      navigate,
   })
}

export function getMonthAndDate(dateData) {
   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ]
   const newDate = new Date(dateData)

   const month = months[newDate.getMonth()]
   const date = newDate.getDate()

   if (date === 1 || date === 21 || date === 31) return `${month} ${date}st`
   if (date === 2 || date === 22) return `${month} ${date}nd`
   if (date === 3 || date === 23) return `${month} ${date}rd`

   return `${month} ${date}th`
}

export async function getNotifications({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getNotifications",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getNotifications",
   })
}

export async function markNotifications({ navigate, idArr }) {
   const config = {
      method: "post",
      url: "/api/client/markNotifications",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         idArr: idArr,
      },
   }

   return await executeAxios({ config, navigate })
}

// feed functions

export async function getClientFeedData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/clientFeedView",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getClientFeedData",
   })
}

export async function changePhotoLike(goalID, isCurrentLiker, navigate) {
   const config = {
      method: "post",
      url: "/api/client/changePhotoLike",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         goalID,
         isCurrentLiker,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function writeComment(userGoalID, comment, navigate) {
   const config = {
      method: "post",
      url: "/api/client/writeComment",
      withCredentials,
      headers,
      params: {
         userGoalId: userGoalID,
         comment: comment,
         email: sessionStorage.getItem("email"),
      },
   }

   return await executeAxios({ config, navigate })
}

export async function getSingleGoalFeed(goalID, navigate) {
   const config = {
      method: "get",
      url: "/api/client/getSingleGoalFeed",
      withCredentials,
      headers,
      params: {
         goalID: goalID,
      },
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getSingleGoalFeed",
   })
}

export async function reportClientGoalPhoto({
   navigate,
   reportedUserId,
   userGoalIndex,
   reportedUserEmail,
   reportedUserName,
   reportedPhoto,
   goalName,
   reportMessage,
}) {
   const config = {
      method: "post",
      url: "/api/client/reportPhoto",
      withCredentials: true,
      headers,
      params: {
         clientEmail: sessionStorage.getItem("email"),
         reportedUserEmail,
         reportedUserName,
         orgID: sessionStorage.getItem("orgID"),
         userGoalIndex,
         reportedPhoto,
         reportedUserId,
         goalName,
         reportMessage,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function sendDirectMessageToUser({
   recipientAccepterUserIndex,
   senderName,
   senderEmail,
   senderMessage,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/client/sendDirectMessageToUser",
      withCredentials: true,
      headers,
      params: {
         recipientAccepterUserIndex,
         senderName,
         senderEmail,
         senderMessage,
      },
   }

   return await executeAxios({ config, navigate })
}

// goals functions

export async function getPastOrgGoals({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getPastOrgGoals",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getPastOrgGoals",
   })
}
export async function getCurrentOrgGoals({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getCurrentOrgGoals",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getCurrentOrgGoals",
   })
}
export async function getFutureOrgGoals({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getFutureOrgGoals",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getFutureOrgGoals",
   })
}

export async function getSingleGoalData({
   navigate,
   abortController,
   organizationGoalID,
   email,
}) {
   const config = {
      method: "get",
      url: "/api/client/getSingleGoal",
      withCredentials,
      headers,
      params: {
         organizationGoalID,
         email,
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getSingleGoalData",
   })
}

export async function startClientGoal({
   navigate,
   daysToComplete,
   investedAmt,
   currency,
   orgGoalID,
   updatedAt,
}) {
   const config = {
      method: "post",
      url: "/api/client/startGoal",
      withCredentials,
      headers,
      data: {
         orgGoalID,
         email: sessionStorage.getItem("email"),
         orgID: sessionStorage.getItem("orgID"),
         investedAmt,
         daysToComplete,
         currency,
         updatedAt,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function createClientGoal({
   clientName,
   clientEmail,
   formD,
   nameValue,
   descriptionValue,
   rewardMinValue,
   rewardMaxValue,
   rewardStaticValue,
   investmentMinValue,
   investmentMaxValue,
   verificationValue,
   verificationCountValue,
   daysToCompleteValue,
   categoryValue,
   openDateValue,
   closeDateValue,
   difficultyValue,
   navigate,
}) {
   const config = {
      method: "post",
      url: "/api/client/createClientGoal",
      withCredentials,
      headers,
      data: formD,
      params: {
         clientName,
         clientEmail,
         name: nameValue,
         description: descriptionValue,
         rewardMin: rewardMinValue,
         rewardMax: rewardMaxValue,
         rewardStatic: rewardStaticValue,
         investmentMin: investmentMinValue,
         investmentMax: investmentMaxValue,
         verification: verificationValue,
         verificationCount: verificationCountValue,
         daysToComplete: daysToCompleteValue,
         category: categoryValue,
         openDate: openDateValue,
         closeDate: closeDateValue,
         difficulty: difficultyValue,
         orgID: sessionStorage.getItem("orgID"),
      },
   }

   return await executeAxios({ config, navigate })
}

export async function getOngoingUserGoals({ abortController, navigate }) {
   const config = {
      method: "get",
      url: "/api/client/getOngoingUserGoals",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getOngoingUserGoals",
   })
}
export async function getPendingUserGoals({ abortController, navigate }) {
   const config = {
      method: "get",
      url: "/api/client/getPendingUserGoals",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getPendingUserGoals",
   })
}
export async function getRestartedUserGoals({ abortController, navigate }) {
   const config = {
      method: "get",
      url: "/api/client/getRestartedUserGoals",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getRestartedUserGoals",
   })
}
export async function getCompletedUserGoals({ abortController, navigate }) {
   const config = {
      method: "get",
      url: "/api/client/getCompletedUserGoals",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getCompletedUserGoals",
   })
}

export async function getFailedUserGoals({ abortController, navigate }) {
   const config = {
      method: "get",
      url: "/api/client/getFailedUserGoals",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getFailedUserGoals",
   })
}

export async function getFailedUserGoalsDonations({
   abortController,
   navigate,
}) {
   const config = {
      method: "get",
      url: "/api/client/getFailedUserGoalsDonations",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getFailedUserGoalsDonations",
   })
}

export async function forfeitRestartForUserGoal({ navigate, userGoalIndex }) {
   const config = {
      method: "post",
      url: "/api/client/forfeitGoal",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         userGoalIndex,
      },
   }

   return await executeAxios({
      config,
      navigate,
   })
}

export async function acknowledgeRestartForUserGoal({
   navigate,
   userGoalIndex,
}) {
   const config = {
      method: "post",
      url: "/api/client/acknowledgeRestart",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         userGoalIndex,
      },
   }

   return await executeAxios({
      config,
      navigate,
   })
}

// profile functions

export async function submitGoal({
   goal,
   navigate,
   userGoalIndex,
   rewardAmount,
}) {
   const config = {
      method: "post",
      url: "/api/client/submitGoal",
      withCredentials,
      headers,
      params: {
         goal,
         clientEmail: sessionStorage.getItem("email"),
         orgID: sessionStorage.getItem("orgID"),
         userGoalIndex,
         rewardAmount,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function addRewardAmount({
   navigate,
   userGoalIndex,
   rewardAmount,
}) {
   const config = {
      method: "post",
      url: "/api/client/addRewardAmount",
      withCredentials,
      headers,
      params: {
         userGoalIndex,
         rewardAmount,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function addUploadedPictureForVerification({
   formD,
   userGoalID,
   visibleToPublic,
   navigate,
   clientEmail,
   orgID,
   goalName,
   verificationCount,
   updatedAt,
}) {
   const config = {
      method: "post",
      url: "/api/client/verifyGoal",
      withCredentials,
      headers,
      data: formD,
      params: {
         userGoalID,
         visibleToPublic: visibleToPublic,
         clientEmail,
         orgID,
         goalName,
         verificationCount,
         updatedAt,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function addPoints(id, amount, navigate) {
   const config = {
      method: "post",
      url: "/api/client/addPoints",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         orgID: sessionStorage.getItem("orgID"),
         id: id,
         amount: amount,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function getUserPoints({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getUserPoints",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getUserPoints",
   })
}

export async function deletePhoto({
   goalID,
   pictureID,
   navigate,
   clientEmail,
   orgID,
   goalName,
}) {
   const config = {
      method: "post",
      url: "/api/client/deletePhoto",
      withCredentials,
      headers,
      params: {
         goalID,
         pictureID,
         clientEmail,
         orgID,
         goalName,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function replaceVerifyPhoto({
   userGoalID,
   newPhotoFormData,
   oldPhotoID,
   visibleToPublic,
   navigate,
   clientEmail,
   orgID,
   goalName,
   updatedAt,
}) {
   const config = {
      method: "post",
      url: "/api/client/replaceVerifyPhoto",
      withCredentials,
      headers,
      data: newPhotoFormData,
      params: {
         userGoalID,
         oldPhotoID,
         visibleToPublic,
         clientEmail,
         orgID,
         goalName,
         updatedAt,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function getUserTransactionDetails(navigate, abortController) {
   const config = {
      method: "get",
      url: "/api/client/getTransactionDetails",
      withCredentials,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getUserTransactionDetails",
   })
}

export async function uploadProfilePic({ formD, navigate }) {
   const config = {
      method: "post",
      url: "/api/client/uploadProfilePic",
      withCredentials: true,
      headers: {
         "Content-Type": "multipart/form-data",
      },
      data: formD,
      params: {
         email: sessionStorage.getItem("email"),
      },
   }

   return await executeAxios({ config, navigate })
}

// rewards functions

export async function getPurchasedRewards({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/fetchPurchasedRewards",
      withCredentials: true,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getRewardsData",
   })
}

export async function getRedeemedRewards({
   navigate,
   acceptedUserIndex,
   abortController,
}) {
   const config = {
      method: "get",
      url: "/api/client/getRedeemedRewards",
      withCredentials: true,
      headers,
      params: {
         acceptedUserIndex,
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getRedeemedRewards",
   })
}

export async function getOrgRewards({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/fetchRewards",
      withCredentials: true,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email: sessionStorage.getItem("email"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getRewardsData",
   })
}

export async function purchaseReward({
   navigate,
   selectedReward,
   purchaseTime,
}) {
   const config = {
      method: "post",
      url: "/api/client/purchaseReward",
      withCredentials: true,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         reward: selectedReward,
         purchaseTime,
         orgID: sessionStorage.getItem("orgID"),
      },
   }

   return await executeAxios({ config, navigate })
}

export async function redeemReward({ inventoryIndex, navigate, rewardData }) {
   const config = {
      method: "post",
      url: "/api/client/redeemReward",
      withCredentials: true,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         orgID: sessionStorage.getItem("orgID"),
         inventoryIndex,
         rewardData,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function addRewardComment({
   navigate,
   comment,
   inventoryIndex,
   commenterEmail,
}) {
   const config = {
      method: "post",
      url: "/api/client/addRewardComment",
      withCredentials: true,
      headers,
      params: {
         comment,
         commenterEmail,
         inventoryIndex,
      },
   }

   return await executeAxios({ config, navigate })
}

export async function changeRewardLike({
   navigate,
   likerEmail,
   inventoryIndex,
   isCurrentLiker,
   abortController,
}) {
   const config = {
      method: "post",
      url: "/api/client/changeRewardLike",
      withCredentials: true,
      headers,
      params: {
         likerEmail,
         isCurrentLiker,
         inventoryIndex,
      },
      signal: abortController.signal,
   }

   return await executeAxios({ config, navigate })
}

export async function getFailedGoalsData({ navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getFailedGoals",
      withCredentials: true,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         orgID: sessionStorage.getItem("orgID"),
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getFailedGoalsData",
   })
}

export async function markDonated({ navigate, userGoalID, donationInfo }) {
   const config = {
      method: "get",
      url: "/api/client/markDonated",
      withCredentials: true,
      headers,
      params: {
         userGoalID,
         donationInfo,
      },
   }

   return await executeAxios({
      config,
      navigate,
   })
}

/*** Profile ***/

export async function getUserInfo({ navigate, abortController, userIndex }) {
   const config = {
      method: "get",
      url: "/api/client/getUserInfo",
      withCredentials: true,
      headers,
      params: {
         email: sessionStorage.getItem("email"),
         userIndex,
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getUserInfo",
   })
}

export async function getProfileFeed({ email, navigate, abortController }) {
   const config = {
      method: "get",
      url: "/api/client/getProfileFeed",
      withCredentials,
      headers,
      params: {
         orgID: sessionStorage.getItem("orgID"),
         email,
      },
      signal: abortController.signal,
   }

   return await executeAxios({
      config,
      navigate,
      functionName: "getProfileFeed",
   })
}

export async function addUserInterest({
   navigate,
   abortController,
   userIndex,
   title,
   description,
   image,
}) {
   const config = {
      method: "post",
      url: "/api/client/addUserInterest",
      withCredentials: true,
      headers,
      params: {
         userIndex,
         title,
         description,
         image,
      },
      signal: abortController.signal,
   }

   return await executeAxios({ config, navigate })
}

export async function removeUserInterest({
   navigate,
   abortController,
   userIndex,
   userInterestIndex,
}) {
   const config = {
      method: "post",
      url: "/api/client/removeUserInterest",
      withCredentials: true,
      headers,
      params: {
         userIndex,
         userInterestIndex,
      },
      signal: abortController.signal,
   }

   return await executeAxios({ config, navigate })
}

export async function editUserInterest({
   navigate,
   abortController,
   userIndex,
   userInterestIndex,
   title,
   description,
   image,
}) {
   const config = {
      method: "post",
      url: "/api/client/editUserInterest",
      withCredentials: true,
      headers,
      params: {
         userIndex,
         userInterestIndex,
         title,
         description,
         image,
      },
      signal: abortController.signal,
   }

   return await executeAxios({ config, navigate })
}

export async function saveConversation({
   navigate,
   abortController,
   recipients,
}) {
   const senderEmail = sessionStorage.getItem("email")
   const config = {
      method: "post",
      url: "/api/client/saveConversation",
      withCredentials: true,
      headers,
      params: {
         recipients: [...recipients, senderEmail],
      },
      signal: abortController.signal,
   }

   return await executeAxios({ config, navigate })
}

export async function saveMessage({
   navigate,
   abortController,
   recipients,
   text,
}) {
   const senderEmail = sessionStorage.getItem("email")
   const config = {
      method: "post",
      url: "/api/client/saveMessage",
      withCredentials: true,
      headers,
      params: {
         recipients: [...recipients, senderEmail],
         text,
         sender: senderEmail,
      },
      signal: abortController.signal,
   }

   return await executeAxios({ config, navigate })
}
