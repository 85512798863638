import React from "react"
import DialogContent from "@mui/material/DialogContent"
import Dialog from "@mui/material/Dialog"
import PaypalPayments from "../../views/client/pagesClient/verifyClient/childComponents/buttons/donate/PaypalDonations"
import { PAYPAL_BUTTON_TYPE } from "../../utilities/enums"
import "./signup.css"

export default function PaymentPopup({
   open,
   setOpen,
   signUpBody,
   setSuccess,
}) {
   const handleClose = () => {
      setOpen(false)
   }

   return (
      <Dialog
         open={open}
         onClose={handleClose}
         sx={{
            "& .MuiDialog-container": {
               "& .MuiPaper-root": {
                  borderRadius: "40px",
                  maxWidth: "500px",
                  maxHeight: "100vh",
               },
            },
         }}
      >
         <DialogContent>
            <div className="client__feed__content__feed__notification__wrapper">
               <p className="client__feed__content__feed__header__text">
                  You must make a self investment of $10 to be able to create an
                  individual account
               </p>
               <div className="client__feed__content__feed__notification__buttons__wrapper">
                  <button
                     className="client__feed__content__feed__notification__upload__button"
                     onClick={handleClose}
                  >
                     Close
                  </button>
                  <PaypalPayments
                     buttonType={PAYPAL_BUTTON_TYPE.CHECKOUT}
                     amountToPay={10}
                     signUpBody={signUpBody}
                     setSuccess={setSuccess}
                  />
               </div>
            </div>
         </DialogContent>
      </Dialog>
   )
}
