import React, { Suspense } from "react"
import { Link, useNavigate, Outlet } from "react-router-dom"
import Footer from "./footer"
import { HashLink } from "react-router-hash-link"
import logo from "../../assets/logo.svg"

import "./topNav.css"

const TopNav = () => {
   const navigate = useNavigate()

   return (
      <>
         <div className="top-nav--wrapper">
            <img src={logo} alt="logo" />
            <div className="top-nav--options">
               <Link to="/">Home</Link>
               <Link to="/aboutUs">About Us</Link>
               <HashLink to="/#features">Features</HashLink>
               <Link to="/getDemo">Get Demo</Link>
               <button
                  onClick={() => {
                     navigate("/login")
                  }}
               >
                  Sign In
               </button>
            </div>
         </div>
         <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
         </Suspense>
         <Footer />
      </>
   )
}

export default TopNav
