import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import AuthForm from "../components/authForm"
import { handleSignUp } from "../../utilities/landingPageAPIs"
import {
   AUTHENTICATION_COMPONENTS,
   SIGN_UP_ORG_ID,
} from "../../utilities/enums"
import PaymentPopup from "./PaymentPopup"

function SignUpForm() {
   const [success, setSuccess] = useState("")
   const [signUpBody, setSignUpBody] = useState("")
   const [open, setOpen] = useState(false)

   const firstNameRef = useRef()
   const lastNameRef = useRef()
   const numberRef = useRef()
   const emailRef = useRef()
   const passwordRef = useRef()
   const confirmPasswordRef = useRef()
   const organizationRef = useRef()
   const termsOfServiceRef = useRef()

   const navigate = useNavigate()

   useEffect(() => {
      if (success) {
         navigate("/login")
      }
   }, [navigate, success])

   function register(event) {
      event.preventDefault()

      const queryParams = new URLSearchParams(window.location.search)
      const accountType = queryParams.get("accountType")

      const firstNameValue = firstNameRef.current.value
      const lastNameValue = lastNameRef.current.value
      const emailValue = emailRef.current.value
      const passwordValue = passwordRef.current.value
      const orgIDValue = organizationRef.current.value.trim()
      const phoneNumberValue = numberRef.current.value
      const termsOfServiceIsChecked = termsOfServiceRef.current.checked

      if (
         !firstNameValue ||
         !lastNameValue ||
         !emailValue ||
         !passwordValue ||
         !orgIDValue ||
         !phoneNumberValue
      ) {
         alert("Please enter all the fields")
         return
      }

      if (!termsOfServiceIsChecked) {
         alert(
            "You need to check the terms of service box to be able to sign up"
         )
         return
      }

      const fullNameValue = `${firstNameValue} ${lastNameValue}`
      const body = JSON.stringify({
         name: fullNameValue,
         email: emailValue,
         password: passwordValue,
         orgID: orgIDValue,
         phoneNumber: phoneNumberValue,
         termsOfServiceIsChecked: true,
      })

      if (accountType !== SIGN_UP_ORG_ID.INDIVIDUAL) {
         handleSignUp({ body, setSuccess: setSuccess })
         return
      }

      setSignUpBody(body)
      setOpen(true)
   }

   return (
      <>
         <AuthForm
            firstNameRef={firstNameRef}
            lastNameRef={lastNameRef}
            numberRef={numberRef}
            emailRef={emailRef}
            passwordRef={passwordRef}
            confirmPasswordRef={confirmPasswordRef}
            organizationRef={organizationRef}
            currentPage={AUTHENTICATION_COMPONENTS.SIGN_UP}
            submitFunction={register}
            termsOfServiceRef={termsOfServiceRef}
         />
         <PaymentPopup
            open={open}
            setOpen={setOpen}
            signUpBody={signUpBody}
            setSuccess={setSuccess}
         />
      </>
   )
}

export default SignUpForm
