import { React, useState } from "react"
import { useNavigate } from "react-router-dom"
import { markNotifications } from "../../../utilities/clientPageApis"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"

import notificationOn from "../../../assets/top-notification-new.svg"
import notificationOff from "../../../assets/top-notification-nonew.png"
import notificationsTextImage from "../../../assets/notifications-text-image.svg"
import smallDownArrow from "../../../assets/small-down-arrow.svg"
import "./notifications.css"

const Notifications = ({
   newNotifications,
   oldNotifications,
   setOldNotifications,
   setNewNotifications,
}) => {
   const navigate = useNavigate()
   const [open, setOpen] = useState(false)
   const [showRecentNotificationGroup, setShowRecentNotificationGroup] =
      useState(true)
   const [showOldNotificationGroup, setShowOldNotificationGroup] =
      useState(true)

   const handleClickOpen = async () => {
      setOpen(true)
      if (newNotifications.length === 0) return
      const idArr = newNotifications.map((notification) => notification._id)
      try {
         await markNotifications({ navigate, idArr })
      } catch (err) {
         console.error(err)
      }
   }

   const handleClose = () => {
      setOpen(false)
      setTimeout(() => {
         setOldNotifications((prevOldNotifications) => [
            ...prevOldNotifications,
            ...newNotifications,
         ])
         setNewNotifications([])
      }, 1000)
   }

   const notificationsDisplay = (notificationsArray) => {
      // this needs to happen from the backend
      const flipped = notificationsArray.slice(0).reverse()
      return flipped.map((notification) => {
         const month = new Date(notification.date).getMonth() + 1
         const date = new Date(notification.date).getDate()

         let text = ""
         let notificationType = ""
         let notificationInfo = JSON.parse(notification.content)

         if (notificationType === undefined) {
            notificationType = "Loading"
            notificationInfo = "Loading"
            text = "Loading"
         }

         if (notification.type === "redeem-reward") {
            notificationType = "Reward Redeemed"
            notificationInfo = notificationInfo?.reward.name
            text = `You redeemed "${notificationInfo?.name}"`
         }

         if (notification.type === "start-goal") {
            notificationType = "Goal Started"
            text = `You started the goal "${notificationInfo?.name}"`
         }

         if (notification.type === "created-goal-approval-status") {
            notificationType = `Created Goal Status: ${
               notificationInfo.approved === "true" ? "Approved" : "Rejected"
            }`
            text = `You created the goal "${notificationInfo.goalName}"`
         }

         if (notification.type === "created-goal-approval-pending") {
            notificationType = "Created Goal Status: Pending"
            text = `You created the goal "${notificationInfo.goalName}"`
         }

         if (notification.type === "purchase-reward") {
            notificationType = "Reward Purchased"
            text = `You purchased "${notificationInfo?.name}"`
         }

         if (notification.type === "suspicious-goal") {
            notificationType = "Suspicious Goal"
            text = `One of your pictures in your goal "${notificationInfo?.name}" has been flagged as suspicious`
         }
         if (notification.type === "submit-goal") {
            notificationType = "Goal Completed"
            text = `You have completed the goal "${notificationInfo?.name}"`
         }

         if (notification.type === "purchase-points") {
            notificationType = "Points Purchased"
            text = `You have purchased ${notification.content} points!`
         }

         if (notification.type === "upload-photo") {
            notificationType = "Picture Uploaded"
            text = `You have uploaded a picture for the goal, ${notificationInfo.goalName}!`
         }

         if (notification.type === "client-reported-photo") {
            notificationType = "You Reported a Picture"
            text = `You have reported a picture uploaded by ${notificationInfo.reportedUserName}!`
         }

         if (notification.type === "client-photo-has-been-reported") {
            notificationType = "Your Picture Has Been Reported"
            text = `Someone has reported one of your uploaded picture for the goal, ${notificationInfo.goalName}!`
         }

         if (notification.type === "rewarded-for-goal-completion") {
            notificationType = "Reward Points Achieved"
            text = `You have earned ${notificationInfo.rewardAmount} points for completing the goal, ${notificationInfo.goalName}!`
         }

         if (notification.type === "reported-photo-evaluation-accepted") {
            notificationType = "Reported Photo Evaluation: Accepted"
            text = `One of the reported pictures for the goal, "${notificationInfo?.goalName}" has been accepted`
         }

         if (notification.type === "reported-photo-evaluation-rejected") {
            notificationType = "Reported Photo Evaluation: Rejected"
            text = `One of the reported pictures for the goal, "${notificationInfo?.goalName}" has been rejected`
         }

         if (notification.type === "deleted-uploaded-photo") {
            notificationType = "Deleted Uploaded Photo"
            text = `You have deleted one of your uploaded pictures for the goal, "${notificationInfo?.goalName}"`
         }

         if (notification.type === "replaced-uploaded-photo") {
            notificationType = "Replaced Uploaded Photo"
            text = `You have replaced one of your uploaded pictures for the goal, "${notificationInfo?.goalName}"`
         }

         if (notification.type === "goal-restarted") {
            notificationType = "Goal Restarted"
            text = `The goal, "${notificationInfo?.goalName}" has been restarted`
         }

         if (notification.type === "goal-failed") {
            notificationType = "Goal Failed"
            text = `You have failed the goal, "${notificationInfo?.goalName}"`
         }

         return (
            <div
               className="notifications__body__content__wrapper"
               key={notification._id}
            >
               <img
                  className="notifications__body__content__image"
                  src={notificationsTextImage}
                  alt="notifications text img"
               />
               <div className="notifications__body__content__inside__wrapper">
                  <div className="notifications__body__content">
                     <h3 className="notifications__body__content__header">
                        {notificationType}
                     </h3>
                     <p className="notifications__body__content__text">
                        {text}
                     </p>
                  </div>
                  <div className="notifications__body__content__date">
                     {`${month}/${date}`}
                  </div>
               </div>
            </div>
         )
      })
   }

   return (
      <div className="testing">
         <button
            className="notifications__bell"
            variant="outlined"
            onClick={handleClickOpen}
         >
            <img
               src={
                  newNotifications.length > 0 ? notificationOn : notificationOff
               }
               alt="notification icon"
            />
         </button>
         <Dialog
            open={open}
            onClose={handleClose}
            sx={{
               "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                     width: "90%",
                     height: "60%",
                     marginTop: "0",
                     marginRight: "0",
                     marginBottom: "0",
                     marginLeft: "0",
                     borderRadius: "12%",
                  },
               },
            }}
         >
            <DialogContent className="notifications__window__body">
               <div className="notifications__wrapper">
                  <div className="notifications__header__wrapper">
                     <h1 className="notifications__header__text">
                        Notifications
                     </h1>
                     {newNotifications.length > 0 && (
                        <div className="notifications__header__number">
                           {newNotifications.length}
                        </div>
                     )}
                  </div>
                  <div className="notifications__body__wrapper">
                     {newNotifications.length > 0 && (
                        <div className="notifications__body__new__wrapper">
                           <div className="notifications__body__header__wrapper">
                              <h2 className="notifications__body__header__text">
                                 Recent
                              </h2>
                              <img
                                 className="notifications__body__header__image"
                                 src={smallDownArrow}
                                 alt="small down arrow"
                                 onClick={() =>
                                    setShowRecentNotificationGroup(
                                       (prev) => !prev
                                    )
                                 }
                              />
                           </div>
                           {showRecentNotificationGroup &&
                              notificationsDisplay(newNotifications)}
                        </div>
                     )}
                     <div className="notifications__body__old__wrapper">
                        <div className="notifications__body__header__wrapper">
                           <h2 className="notifications__body__header__text">
                              Old
                           </h2>
                           <img
                              className="notifications__body__header__image"
                              src={smallDownArrow}
                              alt="small down arrow"
                              onClick={() =>
                                 setShowOldNotificationGroup((prev) => !prev)
                              }
                           />
                        </div>
                        {showOldNotificationGroup &&
                           notificationsDisplay(oldNotifications)}
                     </div>
                  </div>
               </div>
            </DialogContent>
         </Dialog>
      </div>
   )
}

export default Notifications
