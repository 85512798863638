import { useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { PayPalButtons } from "@paypal/react-paypal-js"
import { markDonated } from "../../../../../../../utilities/clientPageApis"
import { PAYPAL_BUTTON_TYPE } from "../../../../../../../utilities/enums"
import { handleSignUp } from "../../../../../../../utilities/landingPageAPIs"

export default function DonateButton({
   amount,
   currency = "USD",
   userGoalID,
   buttonType = PAYPAL_BUTTON_TYPE.DONATE,
   signUpBody,
   setSuccess,
}) {
   const amountRef = useRef(amount)
   const navigate = useNavigate()
   const isDonateButton = buttonType === PAYPAL_BUTTON_TYPE.DONATE

   useEffect(() => {
      amountRef.current = amount
   }, [amount])

   return (
      <PayPalButtons
         style={{
            color: isDonateButton ? "blue" : "black",
            label: isDonateButton
               ? PAYPAL_BUTTON_TYPE.DONATE
               : PAYPAL_BUTTON_TYPE.CHECKOUT,
            width: 200,
            height: isDonateButton ? 35 : 42,
         }}
         fundingSource="paypal"
         createOrder={(_data, actions) => {
            return actions.order.create({
               purchase_units: [
                  {
                     amount: {
                        value: amountRef.current,
                        breakdown: {
                           item_total: {
                              currency_code: currency,
                              value: amountRef.current,
                           },
                        },
                     },
                     items: [
                        {
                           name: isDonateButton ? "Donation" : "Checkout",
                           description:
                              "All proceeds directly goes to non-profits organizations.",
                           quantity: "1",
                           unit_amount: {
                              currency_code: currency,
                              value: amountRef.current,
                           },
                           category: isDonateButton
                              ? "DONATION"
                              : "DIGITAL_GOODS",
                        },
                     ],
                  },
               ],
            })
         }}
         onApprove={async (_data, actions) => {
            const details = await actions.order.capture()
            const transactionInfo = JSON.stringify({
               orderID: details.id,
               payerInformation: details.payer,
               paymentInformation: details.purchase_units,
            })

            if (isDonateButton) {
               await finishMarkingDonation(
                  navigate,
                  transactionInfo,
                  userGoalID
               )
            } else {
               handleSignUp({ body: signUpBody, setSuccess: setSuccess })
            }

            alert(
               "Transaction completed by " + details.payer.name.given_name + "!"
            )
         }}
         onCancel={() => {
            if (isDonateButton) return
            alert(
               "Transaction cancelled. You must make a self investment of $10 before signing up for an account."
            )
         }}
      />
   )
}

async function finishMarkingDonation(navigate, transactionInfo, userGoalID) {
   try {
      const response = await markDonated({
         navigate,
         donationInfo: transactionInfo,
         userGoalID,
      })

      if (response.status !== 200) {
         alert(
            `Donation was not marked as accepted. Please contact an administrator.`
         )
         return
      }
      alert(`Donation was marked as accepted.`)
   } catch (error) {
      alert(
         "Transaction completed, but the database did not update with the new data. Please contact an administrator"
      )
      console.info(error.message)
   }
}
