import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { logout } from "../../../utilities/clientPageApis"
import useComponentVisible from "../../../utilities/useComponentVisible"

import "./sidebarClient.css"

// import logoExpand from "../../../assets/logo.svg"
import hamburgerIcon from "../../../assets/hamburger-icon.svg"
import feedOn from "../../../assets/feed-icon-on.svg"
import feedOff from "../../../assets/feed-icon.svg"
import homeOn from "../../../assets/home-icon-on.svg"
import homeOff from "../../../assets/home-icon.svg"
import goalsOn from "../../../assets/goals-icon-on.svg"
import goalsOff from "../../../assets/goals-icon.svg"
import verifyOn from "../../../assets/verification-icon-on.svg"
import verifyOff from "../../../assets/verification-icon.svg"
import rewardsOn from "../../../assets/rewards-icon-on.svg"
import rewardsOff from "../../../assets/rewards-icon.svg"
import logoutOff from "../../../assets/sidebar-logout.png"
import donationOff from "../../../assets/donations-icon.svg"
import donationOn from "../../../assets/donations-icon-on.svg"

export default function SidebarClient({
   isComponentVisible,
   setIsComponentVisible,
}) {
   const { ref } = useComponentVisible(false)
   const navigate = useNavigate()
   const { pathname: page } = useLocation()

   const dashboardPage = page.includes("dashboard")
   const goalsPage = page.includes("goals")
   const verifyPage = page.includes("verify")
   const feedPage = page.includes("feed")
   const rewardsPage = page.includes("rewards")
   const donationPage = page.includes("donations")
   const conversationsPage = page.includes("conversations")

   return (
      <div>
         <img
            className="sideBar__admin__toggle__hamburger__icon__outside"
            src={hamburgerIcon}
            alt="hamburger icon"
            onClick={() => {
               setIsComponentVisible((prev) => !prev)
            }}
         />
         <div
            className={`sideBar__client ${
               isComponentVisible && "sideBar__client--open"
            }`}
            ref={ref}
         >
            <div className="sideBar__client__wrapper">
               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     dashboardPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/dashboard")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={dashboardPage ? homeOn : homeOff}
                     alt="home icon"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        dashboardPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Dashboard
                  </p>
               </div>

               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     goalsPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/goals")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={goalsPage ? goalsOn : goalsOff}
                     alt="goals icon"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        goalsPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Goals (Step 1)
                  </p>
               </div>

               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     verifyPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/verify")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={verifyPage ? verifyOn : verifyOff}
                     alt="users page"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        verifyPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Verify (Step 2)
                  </p>
               </div>

               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     feedPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/feed")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={feedPage ? feedOn : feedOff}
                     alt="feed icon"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        feedPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Feed (Step 3)
                  </p>
               </div>

               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     rewardsPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/rewards")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={rewardsPage ? rewardsOn : rewardsOff}
                     alt="rewards icon"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        rewardsPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Rewards (Step 4)
                  </p>
               </div>

               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     donationPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/donations")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={donationPage ? donationOn : donationOff}
                     alt="rewards icon"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        donationPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Donations
                  </p>
               </div>

               <div
                  className={`sideBar__client__navigation__item__wrapper ${
                     conversationsPage &&
                     "sideBar__client__navigation__item__wrapper--selected"
                  }`}
                  onClick={() => {
                     setIsComponentVisible(false)
                     navigate("/client/conversations")
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={conversationsPage ? donationOn : donationOff}
                     alt="rewards icon"
                  />
                  <p
                     className={`sideBar__client__navigation__item__text ${
                        conversationsPage &&
                        "sideBar__client__navigation__item__text--active"
                     }`}
                  >
                     Conversations
                  </p>
               </div>

               <div
                  className="sideBar__client__navigation__item__wrapper"
                  onClick={() => {
                     setIsComponentVisible(false)
                     logout({ navigate })
                  }}
               >
                  <img
                     className="sideBar__client__navigation__item__image"
                     src={logoutOff}
                     alt="logoff icon"
                  />
                  <p className="sideBar__client__navigation__item__text">
                     Sign Out
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}
