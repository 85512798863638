import axios from "axios"

const headers = { "Content-Type": "application/json" }

export const handleGetInTouch = async ({ email, message }) => {
   const config = {
      method: "post",
      url: "/api/landing/getInTouch",
      headers,
      params: {
         email: email,
         message: message,
      },
   }
   return await axios(config)
}

export const handleContactUs = ({ name, email, number, subject, message }) => {
   const config = {
      method: "post",
      url: "/api/landing/contactUs",
      headers,
      params: {
         name: name,
         email: email,
         number: number,
         subject: subject,
         message: message,
      },
   }
   axios(config)
      .then((res) => {})
      .catch((err) => {})
}

export async function handleGetDemo({ name, email, number, employeeCount }) {
   const config = {
      method: "post",
      url: "/api/landing/getDemo",
      headers,
      params: {
         name: name,
         email: email,
         number: number,
         employeeCount: employeeCount,
      },
   }
   return await axios(config)
}

export const handleLogin = ({ body }) => {
   const config = {
      method: "post",
      url: "/api/landing/login",
      withCredentials: true,
      headers,
      data: body,
   }
   axios(config)
      .then(function (response) {
         if (response.status !== 200) {
            alert("Login failed from client side after response")
            return
         }

         sessionStorage.setItem("email", response.data.view.email)
         sessionStorage.setItem("orgID", response.data.view.orgID)
         sessionStorage.setItem("pfp", response.data.view.pfp)
         sessionStorage.setItem("name", response.data.view.name)

         if (response.data.view.anthropyAdmin) {
            window.location.href = `/anthropyAdminDashboard`
            return
         }

         if (response.data.view.clientAdmin) {
            window.location.href = `/admin/dashboard`
            return
         }

         console.log("here")
         sessionStorage.setItem("accepted", response.data.view.accepted)

         window.location.href = response.data.view.accepted
            ? `/client/dashboard`
            : `/preorganization`
      })
      .catch(function (error) {
         console.error(error)
         alert("Invalid email or password")
      })
}

export const handleSignUp = ({ body, setSuccess }) => {
   const config = {
      method: "post",
      url: "/api/landing/registerPendingUser",
      headers,
      data: body,
   }

   axios(config)
      .then(function (response) {
         if (response.status !== 201) {
            alert("Registration failed on client side after response")
            return
         }
         alert("Registration successful, Waiting for admin approval")
         setSuccess(true)
      })
      .catch(function (error) {
         alert(error.response.data.error)
         console.error(error)
      })
}
