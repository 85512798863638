import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { markNotifications } from "../../../utilities/clientPageApis"
import { NOTIFICATION_TYPES } from "../../../utilities/enums"

import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import notificationOn from "../../../assets/top-notification-new.svg"
import notificationOff from "../../../assets/top-notification-nonew.png"
import notificationsTextImage from "../../../assets/notifications-text-image.svg"
import smallDownArrow from "../../../assets/small-down-arrow.svg"

import "./notifications.css"

const Notifications = ({
   oldNotifications,
   newNotifications,
   setRefreshAdminTopBar,
}) => {
   const [open, setOpen] = useState(false)
   const [showRecentNotificationGroup, setShowRecentNotificationGroup] =
      useState(true)
   const [showOldNotificationGroup, setShowOldNotificationGroup] =
      useState(true)

   const navigate = useNavigate()

   const handleClickOpen = async () => {
      setOpen(true)
   }

   const handleClose = async () => {
      setOpen(false)
      if (newNotifications.length === 0) return
      const idArr = newNotifications.map((notification) => notification._id)
      await markNotifications({ navigate, idArr })
      setRefreshAdminTopBar((prev) => !prev)
   }

   if (!oldNotifications || !newNotifications) return <div>Loading...</div>

   const notificationsDisplay = (notifications) => {
      return notifications.map((notification) => {
         const month = new Date(notification.date).getMonth() + 1
         const date = new Date(notification.date).getDate()

         let text = ""
         let notificationType = ""
         let notificationInfo = JSON.parse(notification.content)

         if (notification.type === NOTIFICATION_TYPES.REDEEM_REWARD) {
            const rewardInfo = notificationInfo?.reward
            notificationType = "Reward Redeemed"
            text = `${notification.user} redeemed "${rewardInfo?.name}"`
         }

         if (notification.type === NOTIFICATION_TYPES.START_GOAL) {
            notificationType = "Goal Started"
            text = `${notification.user} started the goal "${notificationInfo?.name}"`
         }

         if (notification.type === NOTIFICATION_TYPES.PURCHASE_REWARD) {
            notificationType = "Reward Purchased"
            text = `${notification.user} purchased reward for a "${notificationInfo?.name}"`
         }

         if (notification.type === NOTIFICATION_TYPES.SUSPICIOUS_GOAL) {
            notificationType = "Suspicious Goal"
            text = `One of the pictures in ${notification.user}'s goal "${notificationInfo?.name}" has been flagged as suspicious`
         }

         if (notification.type === NOTIFICATION_TYPES.SUBMIT_GOAL) {
            notificationType = "Goal Completed"
            text = `${notification.user} has completed the goal "${notificationInfo?.name}"`
         }

         if (
            notification.type ===
            NOTIFICATION_TYPES.CREATED_GOAL_APPROVAL_STATUS
         ) {
            notificationType = `Goal Approval Status: ${
               notificationInfo?.createdBy.approved ? "Approved" : "Rejected"
            }`
            text = `the creation of the goal, ${notificationInfo?.name}, by, ${
               notification.user
            }, has been ${
               notificationInfo?.createdBy.approved ? "approved" : "rejected"
            }`
         }

         if (
            notification.type ===
            NOTIFICATION_TYPES.CREATED_GOAL_APPROVAL_PENDING
         ) {
            notificationType = "Goal Approval Status: Pending"
            text = `The approval of the goal, ${notificationInfo?.name}, created by, ${notification.user},  is pending`
         }

         if (
            notification.type ===
            NOTIFICATION_TYPES.CLIENT_PHOTO_HAS_BEEN_REPORTED
         ) {
            notificationType = "Users Photo Has Been Reported"
            text = `One of the pictures in ${notification.user}'s goal "${notificationInfo?.goalName}" has been flagged as suspicious`
         }

         if (
            notification.type ===
            NOTIFICATION_TYPES.REWARDED_FOR_GOAL_COMPLETION
         ) {
            notificationType = "Users Was Rewarded For Goal Completion"
            text = `${notification.clientEmail} has been rewarded for completing the goal "${notificationInfo?.goalName}"`
         }

         if (
            notification.type ===
            NOTIFICATION_TYPES.REPORTED_PHOTO_EVALUATION_ACCEPTED
         ) {
            notificationType = "Users Reported Photo Evaluation: Accepted"
            text = `One of the reported pictures in ${notification.user}'s goal, "${notificationInfo?.goalName}" has been accepted`
         }

         if (
            notification.type ===
            NOTIFICATION_TYPES.REPORTED_PHOTO_EVALUATION_REJECTED
         ) {
            notificationType = "Users Reported Photo Evaluation: Rejected"
            text = `One of the reported pictures in ${notification.user}'s goal, "${notificationInfo?.goalName}" has been rejected`
         }

         if (notification.type === NOTIFICATION_TYPES.CREATED_GOAL_ADMIN) {
            notificationType = "Admin Created A Goal"
            text = `The admin, ${notification.user}, has created the goal, "${notificationInfo?.goalName}"`
         }

         if (notification.type === NOTIFICATION_TYPES.GOAL_RESTARTED) {
            notificationType = "Goal Restarted"
            text = `The goal, "${notificationInfo?.goalName}", has been restarted for the user, ${notification.user}`
         }

         if (notification.type === NOTIFICATION_TYPES.REPLACED_UPLOADED_PHOTO) {
            notificationType = "User replaced Uploaded Photo"
            text = `The user, ${notification.user}, has replaced a photo in the goal, "${notificationInfo?.goalName}"`
         }

         if (notification.type === NOTIFICATION_TYPES.UPLOAD_PHOTO) {
            notificationType = "User has uploaded a photo"
            text = `${notification.user} has uploaded a photo for the goal "${notificationInfo?.goalName}"`
         }

         if (notification.type === NOTIFICATION_TYPES.DELETED_UPLOADED_PHOTO) {
            notificationType = "User has deleted a photo"
            text = `${notification.user} has deleted a photo from the goal "${notificationInfo?.goalName}"`
         }

         if (notification.type === NOTIFICATION_TYPES.GOAL_FAILED) {
            notificationType = "User has Failed a Goal"
            text = `${notification.user} has failed the goal "${notificationInfo?.goalName}"`
         }

         if (notification.type === NOTIFICATION_TYPES.DELETED_GOAL_ADMIN) {
            notificationType = "Admin has deleted a Goal"
            text = `${notification.user} has deleted the goal "${notificationInfo?.goalName}"`
         }

         return (
            <div
               className="admin__notifications__body__content__wrapper"
               key={notification._id}
            >
               <img
                  className="admin__notifications__body__content__image"
                  src={notificationsTextImage}
                  alt="notifications text img"
               />
               <div className="admin__notifications__body__content__inside__wrapper">
                  <div className="admin__notifications__body__content">
                     <h3 className="admin__notifications__body__content__header">
                        {notificationType}
                     </h3>
                     <p className="admin__notifications__body__content__text">
                        {text}
                     </p>
                  </div>
                  <div className="admin__notifications__body__content__date">
                     {`${month}/${date}`}
                  </div>
               </div>
            </div>
         )
      })
   }

   return (
      <div className="testing">
         <button
            className="admin__notifications__bell"
            variant="outlined"
            onClick={handleClickOpen}
         >
            <img
               src={
                  newNotifications.length > 0 ? notificationOn : notificationOff
               }
               alt="notification icon"
            />
         </button>
         <Dialog
            open={open}
            onClose={handleClose}
            sx={{
               "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                     width: "90%",
                     height: "60%",
                     marginTop: "0",
                     marginRight: "0",
                     marginBottom: "0",
                     marginLeft: "0",
                     borderRadius: "12%",
                  },
               },
            }}
         >
            <DialogContent className="admin__notifications__window__body">
               <div className="admin__notifications__wrapper">
                  <div className="admin__notifications__header__wrapper">
                     <h1 className="admin__notifications__header__text">
                        Notifications
                     </h1>
                     {newNotifications.length > 0 && (
                        <div className="admin__notifications__header__number">
                           {newNotifications.length}
                        </div>
                     )}
                  </div>
                  <div className="admin__notifications__body__wrapper">
                     {newNotifications.length > 0 && (
                        <div className="admin__notifications__body__new__wrapper">
                           <div className="admin__notifications__body__header__wrapper">
                              <h2 className="admin__notifications__body__header__text">
                                 Recent
                              </h2>
                              <img
                                 className="admin__notifications__body__header__image"
                                 src={smallDownArrow}
                                 alt="small down arrow"
                                 onClick={() =>
                                    setShowRecentNotificationGroup(
                                       (prev) => !prev
                                    )
                                 }
                              />
                           </div>
                           {showRecentNotificationGroup &&
                              notificationsDisplay(newNotifications)}
                        </div>
                     )}
                     <div className="admin__notifications__body__old__wrapper">
                        <div className="admin__notifications__body__header__wrapper">
                           <h2 className="admin__notifications__body__header__text">
                              Old
                           </h2>
                           <img
                              className="admin__notifications__body__header__image"
                              src={smallDownArrow}
                              alt="small down arrow"
                              onClick={() =>
                                 setShowOldNotificationGroup((prev) => !prev)
                              }
                           />
                        </div>
                        {showOldNotificationGroup &&
                           notificationsDisplay(oldNotifications)}
                     </div>
                  </div>
               </div>
            </DialogContent>
         </Dialog>
      </div>
   )
}

export default Notifications
