import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import DonateButton from "./DonateButton"

export default function PaypalPayments({
   failedGoal,
   buttonType,
   amountToPay = 0,
   signUpBody,
   setSuccess,
}) {
   const amount = amountToPay || failedGoal?.goalInfo.investedAmt

   return (
      <PayPalScriptProvider
         options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            components: "buttons",
            currency: "USD",
         }}
      >
         <DonateButton
            amount={amount}
            userGoalID={failedGoal?._id || 0}
            buttonType={buttonType}
            signUpBody={signUpBody}
            setSuccess={setSuccess}
         />
      </PayPalScriptProvider>
   )
}
