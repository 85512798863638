import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { logout } from "../../../utilities/clientPageApis"
import useComponentVisible from "../../../utilities/useComponentVisible"

import "./sidebarAdmin.css"

import logoExpand from "../../../assets/logo.svg"
import hamburgerIcon from "../../../assets/hamburger-icon.svg"
import feedOn from "../../../assets/feed-icon-on.svg"
import feedOff from "../../../assets/feed-icon.svg"
import homeOn from "../../../assets/home-icon-on.svg"
import homeOff from "../../../assets/home-icon.svg"
import goalsOn from "../../../assets/goals-icon-on.svg"
import goalsOff from "../../../assets/goals-icon.svg"
import userOn from "../../../assets/user-icon-on.svg"
import userOff from "../../../assets/user-icon.svg"
import rewardsOn from "../../../assets/rewards-icon-on.svg"
import rewardsOff from "../../../assets/rewards-icon.svg"
import logoutOff from "../../../assets/sidebar-logout.png"
import reportsOn from "../../../assets/reports-page-icon-on.svg"
import reportsOff from "../../../assets/reports-page-icon.svg"

export default function SidebarAdmin({
   isComponentVisible,
   setIsComponentVisible,
}) {
   const { ref } = useComponentVisible(false)
   const navigate = useNavigate()
   const { pathname: page } = useLocation()

   const dashboardPage = page.includes("dashboard")
   const goalsPage = page.includes("goals")
   const usersPage = page.includes("users")
   const feedPage = page.includes("feed")
   const rewardsPage = page.includes("rewards")
   const reportsPage = page.includes("reports")

   return (
      <div>
         <img
            className="sideBar__admin__toggle__hamburger__icon__outside"
            src={hamburgerIcon}
            alt="hamburger icon"
            onClick={() => setIsComponentVisible((prev) => !prev)}
         />
         <div
            className={`sideBar__admin ${
               isComponentVisible && "sideBar__admin--open"
            }`}
            ref={ref}
         >
            <div className="sideBar__admin__wrapper">
               <div
                  className={`sideBar__admin__navigation__item__wrapper ${
                     dashboardPage &&
                     "sideBar__admin__navigation__item__wrapper--selected"
                  }`}
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     navigate("/admin/dashboard")
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={dashboardPage ? homeOn : homeOff}
                     alt="home icon"
                  />
                  <p
                     className={`sideBar__admin__navigation__item__text ${
                        dashboardPage &&
                        "sideBar__admin__navigation__item__text--active"
                     }`}
                  >
                     Dashboard
                  </p>
               </div>

               <div
                  className={`sideBar__admin__navigation__item__wrapper ${
                     goalsPage &&
                     "sideBar__admin__navigation__item__wrapper--selected"
                  }`}
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     navigate("/admin/goals")
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={goalsPage ? goalsOn : goalsOff}
                     alt="goals icon"
                  />
                  <p
                     className={`sideBar__admin__navigation__item__text ${
                        goalsPage &&
                        "sideBar__admin__navigation__item__text--active"
                     }`}
                  >
                     Goals (Step 1)
                  </p>
               </div>

               <div
                  className={`sideBar__admin__navigation__item__wrapper ${
                     usersPage &&
                     "sideBar__admin__navigation__item__wrapper--selected"
                  }`}
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     navigate("/admin/users")
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={usersPage ? userOn : userOff}
                     alt="users page"
                  />
                  <p
                     className={`sideBar__admin__navigation__item__text ${
                        usersPage &&
                        "sideBar__admin__navigation__item__text--active"
                     }`}
                  >
                     Users
                  </p>
               </div>

               <div
                  className={`sideBar__admin__navigation__item__wrapper ${
                     feedPage &&
                     "sideBar__admin__navigation__item__wrapper--selected"
                  }`}
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     navigate("/admin/feed")
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={feedPage ? feedOn : feedOff}
                     alt="feed icon"
                  />
                  <p
                     className={`sideBar__admin__navigation__item__text ${
                        feedPage &&
                        "sideBar__admin__navigation__item__text--active"
                     }`}
                  >
                     Feed (Step 2)
                  </p>
               </div>

               <div
                  className={`sideBar__admin__navigation__item__wrapper ${
                     rewardsPage &&
                     "sideBar__admin__navigation__item__wrapper--selected"
                  }`}
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     navigate("/admin/rewards")
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={rewardsPage ? rewardsOn : rewardsOff}
                     alt="rewards icon"
                  />
                  <p
                     className={`sideBar__admin__navigation__item__text ${
                        rewardsPage &&
                        "sideBar__admin__navigation__item__text--active"
                     }`}
                  >
                     Rewards (Step 3)
                  </p>
               </div>

               <div
                  className={`sideBar__admin__navigation__item__wrapper ${
                     reportsPage &&
                     "sideBar__admin__navigation__item__wrapper--selected"
                  }`}
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     navigate("/admin/reports")
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={reportsPage ? reportsOn : reportsOff}
                     alt="rewards icon"
                  />
                  <p
                     className={`sideBar__admin__navigation__item__text ${
                        reportsPage &&
                        "sideBar__admin__navigation__item__text--active"
                     }`}
                  >
                     Reports
                  </p>
               </div>

               <div
                  className="sideBar__admin__navigation__item__wrapper"
                  onClick={(e) => {
                     setIsComponentVisible(false)
                     logout({ navigate })
                  }}
               >
                  <img
                     className="sideBar__admin__navigation__item__image"
                     src={logoutOff}
                     alt="logoff icon"
                  />
                  <p className="sideBar__admin__navigation__item__text">
                     Sign Out
                  </p>
               </div>
            </div>
         </div>
      </div>
   )
}
