import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import { PopupButton } from "react-calendly"
import { handleGetInTouch } from "../../utilities/landingPageAPIs"

import "./footer.css"
import {
   emailIcon,
   phoneIcon,
   locationIcon,
   logo,
   facebookIcon,
   instagramIcon,
   linkedInIcon,
} from "../../assets/InitialPageImages"

const Footer = () => {
   const emailRef = useRef()
   const messageRef = useRef()

   const handleFormSubmit = async (e) => {
      e.preventDefault()

      try {
         const response = await handleGetInTouch({
            email: emailRef.current.value,
            message: messageRef.current.value,
         })
         if (response.status === 200) {
            alert("Message sent")
         }
      } catch (error) {
         console.info(error.message)
      }
   }

   return (
      <div className="footer__wrapper">
         <div className="footer__body">
            <div className="summery__wrapper">
               <img className="summery__logo" src={logo} alt="logo"></img>
               <p className="summery__text">
                  Manage projects, organize tasks, maintain leaves, and handle
                  claims with less hassle. Anthropy HR is a system that can be
                  modified to meet all of your company's human resource needs.
               </p>
               <div className="summery__Links">
                  <a
                     href="https://www.facebook.com/AnthropyHR/"
                     target="_blank"
                     rel="noReferrer"
                  >
                     <img src={facebookIcon} alt="facebook" />
                  </a>
                  <a
                     href="https://www.instagram.com/anthropyHR/"
                     target="_blank"
                     rel="noReferrer"
                  >
                     <img src={instagramIcon} alt="instagram" />
                  </a>
                  <a
                     href="https://www.linkedin.com/company/anthropy-consulting/"
                     target="_blank"
                     rel="noReferrer"
                  >
                     <img src={linkedInIcon} alt="linkedin" />
                  </a>
               </div>
            </div>

            <div className="quick__links__wrapper">
               <h3 className="quick__links__header">Quick links</h3>
               <div className="quick__links__link__container">
                  <Link to="/">Home</Link>
                  <Link to="/aboutUs">About Us</Link>
                  <HashLink to="/#features">Features</HashLink>
                  <Link to="/contactUs">Contact Us</Link>
               </div>
            </div>

            <div className="contact__wrapper">
               <h3 className="contact__header">Contact Detail</h3>
               <div className="contact__link__container">
                  <div className="contact__link">
                     <img
                        className="contact__link__image"
                        src={locationIcon}
                        alt="location"
                     />
                     <p className="contact__link__text">
                        Washington DC Metropolitan Area, USA
                     </p>
                  </div>

                  <div className="contact__link">
                     <img
                        className="contact__link__image"
                        src={emailIcon}
                        alt="email"
                     />
                     <PopupButton
                        url="https://calendly.com/anthropyHR"
                        rootElement={document.getElementById("root")}
                        text="Click here to schedule a meeting!"
                        styles={{
                           backgroundColor: "#4d74ff",
                           border: "none",
                           textDecoration: "none",
                           fontFamily: "Rubik",
                           fontStyle: "normal",
                           fontWeight: "400",
                           fontSize: "1.125em",
                           lineHeight: "1.2222em",
                           color: "var(--base-white-color)",
                        }}
                     />
                  </div>

                  <div className="contact__link">
                     <img
                        className="contact__link__image"
                        src={phoneIcon}
                        alt="phone number"
                     />
                     <p className="contact__link__text">+1 302-242-7490</p>
                  </div>
               </div>
            </div>

            <div className="form__wrapper">
               <h3 className="form__header">Get in Touch</h3>
               <form className="form__body" onSubmit={handleFormSubmit}>
                  <input
                     className="form__input"
                     ref={emailRef}
                     placeholder="Email Address"
                     type="email"
                     required
                  />
                  <textarea
                     className="form__textarea"
                     ref={messageRef}
                     rows="4"
                     placeholder="Your Message"
                     required
                  />
                  <button className="form__button" type="submit">
                     Submit
                  </button>
               </form>
            </div>
         </div>

         <hr />

         <div className="copyright__wrapper">
            <p className="copyright__text">
               Copyright © 2023 Anthropy LLC All Rights Reserved{" "}
            </p>
            <div className="copyright__policies">
               <Link to="/">Privacy Policy</Link>
               <Link to="/">Terms & Conditions</Link>
            </div>
         </div>
      </div>
   )
}

export default Footer
