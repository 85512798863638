import React, { useState, useEffect, Suspense } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import SidebarAdmin from "./SidebarAdmin"
import { getAdminNotificationsData } from "../../../utilities/clientAdminPageApi"
import useComponentVisible from "../../../utilities/useComponentVisible"

import "./topBarAdmin.css"
import Notifications from "./notifications"
import logo from "../../../assets/logo.svg"

export default function ClientAdminTopBar() {
   const [oldNotifications, setOldNotifications] = useState([])
   const [newNotifications, setNewNotifications] = useState([])
   const [refreshAdminTopBar, setRefreshAdminTopBar] = useState(false)
   const { isComponentVisible, setIsComponentVisible } =
      useComponentVisible(false)

   const navigate = useNavigate()

   useEffect(() => {
      const abortController = new AbortController()

      async function loadNotificationsData() {
         try {
            const response = await getAdminNotificationsData({
               navigate,
               abortController,
            })

            if (!response.data) return null

            const { notifications } = response.data

            const oldNotifications = []
            const newNotifications = []
            for (const notification of notifications) {
               if (notification.seenBy.includes(sessionStorage.getItem("email")))
                  oldNotifications.push(notification)
               else newNotifications.push(notification)
            }

            setOldNotifications(oldNotifications)
            setNewNotifications(newNotifications)
         } catch (e) {
            console.info(e.message)
         }
      }
      loadNotificationsData()

      return () => abortController.abort()
   }, [navigate, refreshAdminTopBar])

   const openProfile = () => {
      navigate("/admin/profile")
   }

   return (
      <div
         className={`clientAdmin__page__wrapper ${
            isComponentVisible && "clientAdmin__page__wrapper--sidebar__open"
         }`}
      >
         <SidebarAdmin
            isComponentVisible={isComponentVisible}
            setIsComponentVisible={setIsComponentVisible}
         />
         <div
            className={`clientAdmin__black__screen ${
               isComponentVisible && "clientAdmin__black__screen--sidebar__open"
            }`}
            onClick={() => setIsComponentVisible(false)}
         ></div>
         <div
            className={`clientAdmin__page__content__wrapper ${
               isComponentVisible &&
               "clientAdmin__page__content__wrapper--sidebar__open"
            }`}
         >
            <div className="clientAdmin__topBar__wrapper">
               <div className="clientAdmin__logo__image__wrapper">
                  <img
                     className="clientAdmin__logo__image"
                     src={logo}
                     alt="logo"
                  />
               </div>
               <div className="clientAdmin__topBar__content">
                  <Notifications
                     oldNotifications={oldNotifications}
                     newNotifications={newNotifications}
                     setRefreshAdminTopBar={setRefreshAdminTopBar}
                  />
                  <div
                     className="clientAdmin__topBar__profile__wrapper"
                     onClick={openProfile}
                  >
                     <div className="clientAdmin__topBar__profile__image" />
                     <div className="clientAdmin__topBar__profile__name">
                        {sessionStorage.getItem("name")?.split(" ")[0] ||
                           "Default Name"}
                        <br />
                        Org: {sessionStorage.getItem("orgID")}
                     </div>
                     {/* <img
                className="clientAdmin__topBar__profile__image__Arrow"
                src={profileArrow}
                alt="profile arrow"
              /> */}
                  </div>
               </div>
            </div>
            <span className="clientAdmin__head__span"></span>
            <Suspense fallback={<div>Loading...</div>}>
               <Outlet context={[setRefreshAdminTopBar]} />
            </Suspense>
         </div>
      </div>
   )
}
