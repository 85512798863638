import React, { lazy } from "react"
import { Routes as R, Route } from "react-router-dom"
import { SIGN_UP_ORG_ID } from "./utilities/enums"

import TopBarLanding from "./initialPages/components/topNav"
import TopBarClient from "./views/client/componentsClient/TopBarClient"
import TopBarAdmin from "./views/admin/componentsAdmin/TopBarAdmin"
import SignUp from "./initialPages/signup/signup"
import Login from "./initialPages/login/login"

const Landing = lazy(() => import("./initialPages/landing/landing"))
const AboutUs = lazy(() => import("./initialPages/aboutUs/aboutUs"))
const Demo = lazy(() => import("./initialPages/demo/demo"))
const Customers = lazy(() => import("./initialPages/customers/customers"))
const ContactUs = lazy(() => import("./initialPages/contactUs/contactUs"))
const ForgotPassword = lazy(() =>
   import("./initialPages/forgotPassword/ForgotPassword.js")
)

// client pages
const GoalsViewClient = lazy(() =>
   import("./views/client/pagesClient/goalsClient/GoalsViewClient")
)
const VerifyViewClient = lazy(() =>
   import("./views/client/pagesClient/verifyClient/VerifyViewClient")
)
const DashboardViewClient = lazy(() =>
   import("./views/client/pagesClient/dashboardClient/DashboardViewClient")
)
const DashboardSalesViewClient = lazy(() =>
   import(
      "./views/client/pagesClient/dashboardSalesClient/DashboardSalesViewClient"
   )
)
const PreOrganization = lazy(() =>
   import("./views/client/pagesClient/preorganizationClient/PreOrganization")
)
const ProfileViewClient = lazy(() =>
   import("./views/client/pagesClient/profileClient/ProfileViewClient")
)
const RewardViewClient = lazy(() =>
   import("./views/client/pagesClient/rewardsClient/RewardViewClient")
)
const FeedViewClient = lazy(() =>
   import("./views/client/pagesClient/feedClient/FeedViewClient")
)
const SingleGoalFeedClient = lazy(() =>
   import(
      "./views/client/pagesClient/feedClient/childComponents/singleGoalFeed/SingleGoalFeedClient"
   )
)
const ProfileFeedClient = lazy(() =>
   import(
      "./views/client/pagesClient/feedClient/childComponents/profileFeed/ProfileFeedClient"
   )
)
const TransactionsViewClient = lazy(() =>
   import(
      "./views/client/pagesClient/transactionsClient/TransactionsViewClient"
   )
)
const DonationsViewClient = lazy(() =>
   import("./views/client/pagesClient/donationsClient/DonationsViewClient")
)
const ConversationsViewClient = lazy(() =>
   import(
      "./views/client/pagesClient/conversationsClient/ConversationsViewClient"
   )
)

// admin pages
const RewardsViewAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/rewardsAdmin/RewardsViewAdmin")
)
const UsersAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/usersAdmin/UsersAdmin")
)
const GoalsViewAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/goalsAdmin/GoalsViewAdmin")
)
const DashboardViewAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/dashboardAdmin/DashboardViewAdmin")
)
const FeedViewAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/feedAdmin/FeedViewAdmin")
)
const SingleFeedAdmin = lazy(() =>
   import(
      "./views/admin/pagesAdmin/feedAdmin/childComponents/singleFeed/SingleFeedAdmin"
   )
)
const ProfileAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/profileAdmin/ProfileAdmin")
)
const ReportsViewAdmin = lazy(() =>
   import("./views/admin/pagesAdmin/reportsAdmin/ReportsViewAdmin")
)

const AnthropyAdmin = lazy(() =>
   import("./views/anthropyAdmin/dashboard/index")
)

const orgType = sessionStorage.getItem("orgID")

const Routes = () => (
   <R>
      {/*Initial pages routes */}
      <Route path="/" element={<TopBarLanding />}>
         <Route path="/" element={<Landing />} />
         <Route path="aboutUs" element={<AboutUs />} />
         <Route path="getDemo" element={<Demo />} />
         <Route path="customers" element={<Customers />} />
         <Route path="contactUs" element={<ContactUs />} />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="forgot-Password" element={<ForgotPassword />} />

      {/*Anthropy admin routes*/}
      <Route path="/anthropyAdminDashboard" element={<AnthropyAdmin />} />

      {/*Client sidenav routes*/}
      <Route path="/client" element={<TopBarClient />}>
         <Route
            path="dashboard"
            element={
               orgType === SIGN_UP_ORG_ID.SALES ? (
                  <DashboardSalesViewClient />
               ) : (
                  <DashboardViewClient />
               )
            }
         />
         <Route path="verify">
            <Route index element={<VerifyViewClient />} />
            <Route path="needToDonate" element={<VerifyViewClient />} />
         </Route>
         <Route path="goals" element={<GoalsViewClient />} />

         <Route path="feed" element={<FeedViewClient />} />
         <Route path="feed/:goalId" element={<SingleGoalFeedClient />} exact />
         <Route
            path="user/feed/:userIndex"
            element={<ProfileFeedClient />}
            exact
         />

         <Route path="conversations" element={<ConversationsViewClient />} />
         <Route path="rewards" element={<RewardViewClient />} />
         <Route path="profile" element={<ProfileViewClient />} />
         <Route path="transactions" element={<TransactionsViewClient />} />
         <Route path="donations" element={<DonationsViewClient />} />
      </Route>

      <Route path="/preorganization" element={<PreOrganization />} />

      {/*Admin sidenav routes */}
      <Route path="/admin" element={<TopBarAdmin />}>
         <Route path="dashboard" element={<DashboardViewAdmin />} />
         <Route path="users" element={<UsersAdmin />} />
         <Route path="profile" element={<ProfileAdmin />} />
         <Route path="goals" element={<GoalsViewAdmin />} />
         <Route path="rewards" element={<RewardsViewAdmin />} />
         <Route path="reports" element={<ReportsViewAdmin />} />

         <Route path="feed" element={<FeedViewAdmin />} />
         <Route path="feed/:goalId" element={<SingleFeedAdmin />} />
         <Route path="user/feed/:userIndex" element={<ProfileFeedClient />} />
      </Route>
   </R>
)

export default Routes
