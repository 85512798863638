export const NOTIFICATION_TYPES = {
   START_GOAL: "start-goal",
   REDEEM_REWARD: "redeem-reward",
   CREATED_GOAL_APPROVAL_STATUS: "created-goal-approval-status",
   CREATED_GOAL_APPROVAL_PENDING: "created-goal-approval-pending",
   PURCHASE_REWARD: "purchase-reward",
   SUSPICIOUS_GOAL: "suspicious-goal",
   SUBMIT_GOAL: "submit-goal",
   PURCHASE_POINTS: "purchase-points",
   UPLOAD_PHOTO: "upload-photo",
   CLIENT_REPORTED_PHOTO: "client-reported-photo",
   CLIENT_PHOTO_HAS_BEEN_REPORTED: "client-photo-has-been-reported",
   REWARDED_FOR_GOAL_COMPLETION: "rewarded-for-goal-completion",
   REPORTED_PHOTO_EVALUATION_ACCEPTED: "reported-photo-evaluation-accepted",
   REPORTED_PHOTO_EVALUATION_REJECTED: "reported-photo-evaluation-rejected",
   DELETED_UPLOADED_PHOTO: "deleted-uploaded-photo",
   REPLACED_UPLOADED_PHOTO: "replaced-uploaded-photo",
   GOAL_RESTARTED: "goal-restarted",
   GOAL_FAILED: "goal-failed",
   CREATED_GOAL_ADMIN: "created-goal-admin",
   DELETED_GOAL_ADMIN: "deleted-goal-admin",
   GIFT_RECEIVED: "gift-received",
   GIFT_SENT: "gift-sent",
}

export const ORG_GOAL_STATUS = {
   ACTIVE: "active",
   SUSPENDED: "suspended",
}

export const USER_GOAL_STATUS = {
   ONGOING: "ONGOING",
   FAILED: "FAILED",
   START: "START",
   PENDING: "PENDING",
   COMPLETED: "COMPLETED",
   RESTARTED: "RESTARTED",
}

export const REWARD_STATUS = {
   PURCHASED: "PURCHASED",
   REDEEMED: "REDEEMED",
}

export const CHANGE_STATUS = {
   NEXT: "NEXT",
   PREVIOUS: "PREVIOUS",
}

export const USER_GOAL_COMPONENTS = {
   DONATIONS: "donations",
   ONGOING: "ongoing",
   FAILED: "failed",
   RESTARTORFORFEIT: "restart/forfeit",
}

export const AUTHENTICATION_COMPONENTS = {
   SIGN_IN: "signIn",
   SIGN_UP: "signUp",
   FORGOT_PASSWORD: "forgot-password",
   DEMO: "demo",
}

export const SIGN_UP_ORG_ID = {
   INDIVIDUAL: "individual",
   SALES: "sales",
}

export const PAYPAL_BUTTON_TYPE = {
   DONATE: "donate",
   CHECKOUT: "checkout",
}
